<template>
  <v-dialog
    v-model="dialog"
    width="500"
  >
    <template v-slot:activator="{on,attrs}">
      <div style="height: 33px">
        <v-btn
          class="text-none text-white"
          block
          rounded
          small
          v-bind="attrs"
          color="primary"
          elevation="2"
          v-on="on"
        >
          <v-icon class="mr-2">
            mdi-check-box-multiple-outline
          </v-icon>
          Alteração em massa de preços
        </v-btn>
      </div>
    </template>

    <v-card class="my-0">
      <v-form ref="form">
        <v-toolbar
          dark
          color="primary"
        >
          <v-toolbar-title>Opções de alteração</v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn
              icon
              @click="dialog = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-divider />
        <v-container>
          <v-row
            justify="start"
            dense
          >
            <v-col cols="auto">
              <v-btn
                class="text-none text-white"
                color="primary"
                small
                rounded
                @click="CHECK_ALL"
              >
                <v-icon
                  dark
                  left
                >
                  mdi-checkbox-multiple-marked
                </v-icon>
                Marcar Todos
              </v-btn>
            </v-col>
            <v-col cols="auto">
              <v-btn
                class="text-none text-white"
                small
                rounded
                @click="UNCHECK_ALL"
              >
                <v-icon
                  dark
                  left
                >
                  mdi-checkbox-multiple-blank-outline
                </v-icon>
                Desmarcar Todos
              </v-btn>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col
              cols="12"
              sm="5"
            >
              <base-vuetify-money
                v-model="opcoesPreco"
                :options="money"
                :rules="[rules.money]"
                maxlength="10"
                outlined
                rounded
                label="Preço (R$)"
                validate-on-blur
                dense
              />
            </v-col>
            <v-col
              cols="12"
              sm="5"
            >
              <v-text-field
                v-model="opcoesPedidoMin"
                v-mask="'##########'"
                :rules="[rules.numeric, rules.gt(0)]"
                maxlength="10"
                rounded
                outlined
                label="Pedido Mínimo (Quantidade)"
                validate-on-blur
                dense
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-radio-group
                v-model="opcoesDisponibilidade"
                label="Disponibilidade: "
                hide-details
                dense
                row
                class="mt-0"
              >
                <v-radio
                  label="Imediata"
                  :value="1"
                />
                <v-radio
                  label="Sob demanda"
                  :value="0"
                />
                <v-radio
                  label="Sob cotação"
                  :value="2"
                />
                <v-btn
                  class="text-none text-white"
                  small
                  :disabled="opcoesDisponibilidade === null"
                  rounded
                  @click="opcoesDisponibilidade = null"
                >
                  <v-icon
                    dark
                    left
                  >
                    mdi-broom
                  </v-icon>
                  Limpar
                </v-btn>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn
                small
                rounded
                color="error"
                @click="dialog = false"
              >
                <v-icon
                  dark
                  left
                >
                  mdi-minus-circle
                </v-icon>
                Cancelar
              </v-btn>
            </v-col>
            <v-col>
              <v-btn
                class="text-none text-white"
                block
                small
                color="primary"
                :disabled="!anyChecked"
                rounded
                @click="apply"
              >
                <v-icon
                  dark
                  left
                >
                  mdi-checkbox-marked-circle
                </v-icon>
                Aplicar alterações aos selecionados
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card>
  </v-dialog>
</template>
<script>
  import { mapFields } from 'vuex-map-fields'
  import { mapGetters, mapMutations } from 'vuex'
  import rules from '../../utils/formRules'
  import { CHECK_ALL, UNCHECK_ALL, APPLY_TO_CHECKED } from '@/store/modules/forms/catalogo'

  export default {
    name: 'CatalogoDialogMenu',
    data () {
      return {
        dialog: false,
        rules,
        money: {
          locale: 'pt-BR',
          precision: 2,
        },
      }
    },
    computed: {
      ...mapFields('form/catalogo', {
        opcoesPedidoMin: 'opcoes.pedido_min',
        opcoesPreco: 'opcoes.preco',
        opcoesDisponibilidade: 'opcoes.disponibilidade',
      }),
      ...mapGetters('form/catalogo', ['anyChecked']),
    },
    methods: {
      ...mapMutations('form/catalogo', [CHECK_ALL, UNCHECK_ALL, APPLY_TO_CHECKED]),
      apply () {
        if (!this.$refs.form.validate()) {
          return null
        }
        this.APPLY_TO_CHECKED()
      },
    },
  }
</script>
