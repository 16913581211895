<template>
  <v-dialog
    v-model="dialog"
    :hack-validation="hackValidation"
    :max-width="'1000px'"
    persistent
    scrollable
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <base-snack-bar
      v-model="snackbar.show"
      :message="snackbar.message"
    />
    <v-card>
      <v-toolbar
        color="primary"
        dark
      >
        <v-toolbar-title class="headline">
          {{ edit ? 'Editar Produto do Catálogo' : 'Adicionar Produto ao Catálogo' }}
        </v-toolbar-title>
        <v-spacer />
        <v-btn
          icon
          dark
          @click="CANCEL"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-container
        fluid
        class="pb-0"
      >
        <v-row class="styled-container">
          <v-col
            cols="12"
            sm="9"
          >
            <v-form ref="produto">
              <v-autocomplete
                v-model="produto_id"
                :items="produtos"
                item-text="nome"
                item-value="id"
                label="Produto*"
                :clearable="!edit"
                :filter="filterProduto"
                :rules="[rules.required]"
                autocomplete="off"
                :readonly="edit"
                validate-on-blur
                :disabled="edit"
                dense
                hide-details
                outlined
                rounded
                @change="next"
              >
                <template
                  #item="{item}"
                >
                  <v-container
                    class="rounded-lg"
                    fluid
                  >
                    <v-row
                      dense
                      justify="space-between"
                    >
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ item.nome }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          Cod. {{ item.codigo }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-row>
                  </v-container>
                </template>
              </v-autocomplete>
            </v-form>
          </v-col>
          <v-col
            cols="12"
            sm="3"
          >
            <v-text-field
              v-model="produto.codigo"
              label="Código"
              readonly
              hide-details
              dense
              outlined
              rounded
            />
          </v-col>
        </v-row>
      </v-container>
      <v-card-text>
        <cards-catalogo
          v-if="produto_id !== null && produto_id !== undefined"
          ref="cardsForm"
        />
      </v-card-text>
      <v-card-actions
        class="justify-end"
      >
        <v-row justify="end">
          <v-col cols="auto">
            <v-btn
              color="error"
              rounded
              right
              @click="CANCEL"
            >
              <v-icon
                dark
                left
              >
                mdi-minus-circle
              </v-icon>
              Cancelar
            </v-btn>
          </v-col>
          <v-col cols="auto">
            <v-btn
              color="success"
              right
              rounded
              @click="submitForm"
            >
              <v-icon
                dark
                left
              >
                mdi-checkbox-marked-circle
              </v-icon>
              Salvar
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
  import rules from '../../utils/formRules'
  import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
  import { mapFields, mapMultiRowFields } from 'vuex-map-fields'
  import CatalogoFormStore, { BOOTSTRAP, CANCEL, SUBMIT, ADD_ITEM_CATALOGO, NEXT } from '@/store/modules/forms/catalogo'
  import CardsCatalogo from './CardsCatalogo'

  export default {
    components: { CardsCatalogo }, // SingleFormProdutoCatalogo, EditFormCatalogo },
    data () {
      return {
        rules,
        money: {
          locale: 'pt-BR',
          precision: 2,
        },
        snackbar: { message: '', show: false },
      }
    },
    computed: {
      ...mapState('form/catalogo', ['produtos', 'edit']),
      ...mapState('catalogos', {
        unidadesNegocio: state => state.unidades_negocio,
        showDialog: state => state.dialog,
        catalogo: state => state.catalogo,
      }),
      ...mapFields(
        'form/catalogo', [
          'dialog', 'produto_id', 'step', 'selectionMode',
        ],
      ),
      ...mapMultiRowFields('form/catalogo', ['form']),
      ...mapGetters('form/catalogo', ['produto', 'color']),
      hackValidation () { // Hack para resetar validação do formulário. Issue form/v-dialog
        if (this.dialog && this.$refs.form) {
          this.$refs.form.resetValidation()
          this.$refs.produto.resetValidation()
        }
        return null
      },
    },
    created () {
      if (!this.$store.state.form.catalogo) { this.$store.registerModule(['form', 'catalogo'], CatalogoFormStore) }
      this.BOOTSTRAP()
    },
    methods: {
      ...mapActions('form/catalogo', [BOOTSTRAP, CANCEL, SUBMIT, NEXT]),
      ...mapMutations('form/catalogo', [ADD_ITEM_CATALOGO]),
      submitForm () {
        if ((!this.$refs.cardsForm.validate()) || (!this.$refs.produto.validate())) {
          this.snackbar = {
            show: true,
            message: 'Existem campos Obrigatórios não prenchidos. Por favor, preencha-os!',
          }
          return null
        }
        this.SUBMIT()
      },
      next () {
        if ((!this.edit && !this.$refs.produto.validate())) {
          return null
        }
        this.NEXT()
      },
      filterProduto (item, consulta, nome) {
        if (nome.toLowerCase().includes(consulta.toLowerCase())) return true
        if (item.codigo.includes(consulta)) return true
        return false
      },
    },
  }
</script>
<style lang="scss" scoped>

.card-scroll-container {
  overflow: auto
}

.styled-container {
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  margin: 1px;
}

.v-card__text {
  flex-grow: 1;
  overflow: auto;
}

</style>
