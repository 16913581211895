import { getField, updateField } from 'vuex-map-fields'
import { ProdutoCatalogo } from '@/models/ProdutoCatalogo'
import { ADD_PRODUTO_CATALOGO, SET_PRODUTO_CATALOGO, LOAD_BATCH } from '../catalogos'
import api from '@/api/api'
import Swal from 'sweetalert2'
import { v4 } from 'uuid'

export const SUBMIT = 'SUBMIT'
export const READY = 'READY'
export const OPEN_FORM = 'OPEN_FORM'
export const LOAD_PRODUTOS = 'LOAD_PRODUTOS'
export const SET_PRODUTOS = 'GET_PRODUTOS'
export const GET_PRODUTO = 'SET_PRODUTO'
export const BOOTSTRAP = 'BOOTSTRAP'
export const CANCEL = 'CANCEL'
export const EDIT_FORM = 'EDIT_FORM'
export const SET_DIALOG = 'SET_DIALOG'
export const SET_FORMS = 'SET_FORMS'
export const SET_EDIT_FORM = 'SET_EDIT_FORM'
export const SET_PRODUTO = 'SET_PRODUTO'
export const REMOVE_ITEM_CATALOGO = 'REMOVE_ITEM_CATALOGO'
export const REMOVE_CARD_ROW = 'REMOVE_CARD_ROW'
export const ADD_ITEM_CATALOGO = 'ADD_ITEM_CATALOGO'
export const NEXT = 'NEXT'
export const CHECK_ALL = 'CHECK_ALL'
export const UNCHECK_ALL = 'UNCHECK_ALL'
export const TOGGLE_CHECK_ALL = 'TOGGLE_CHECK_ALL'
export const APPLY_TO_CHECKED = 'APPLY_TO_CHECKED'
export const REMOVE_ITEM = 'REMOVE_ITEM'
export const RESET_STATE = 'RESET_STATE'

const getDefaultState = () => {
  return {
    form: [],
    editForm: null,
    produtos: [],
    produto_id: null,
    dialog: false,
    edit: false,
    edit_id: null,
    step: 1,
    selectionMode: false,
    opcoes: {
      pedido_min: null,
      preco: null,
      disponibilidade: null,
    },
  }
}

const state = getDefaultState()

const getters = {
  getField,
  anyChecked: (state) => {
    return state.form.filter(item => item.checked).length > 0
  },
  produto: (state) => {
    if (state.produto_id) {
      const { ...rest } = state.produtos.find(produto => produto.id === state.produto_id)
      return { ...rest }
    } else {
      return {}
    }
  },
  produtoCatalogo: (state, getters, rootState, rootGetters) => {
    return rootGetters['catalogos/catalogo'].filter(item => item.produto_id === state.produto_id)
  },
  color: (state, { produtoCatalogo }) => ({ id: un_id }) => {
    return ~produtoCatalogo
      .findIndex(item => {
        return item.unidade_negocio_id === un_id
      }) ? 'yellow darken-2' : ''
  },
}

const mutations = {
  updateField,
  [RESET_STATE] (state) {
    Object.assign(state, getDefaultState())
  },
  [SET_PRODUTOS] (state, { produtos }) {
    state.produtos = produtos
  },
  [EDIT_FORM] (state, { edit, id = null }) {
    state.edit = edit
    state.edit_id = id
  },
  [SET_DIALOG] (state, { dialog }) {
    state.dialog = dialog
  },
  [SET_EDIT_FORM] (state, { data }) {
    state.editForm = new ProdutoCatalogo(data)
  },
  [SET_PRODUTO] (state, produto_id) {
    state.produto_id = produto_id
  },
  [SET_FORMS] (state, { items }) {
    state.form = items.map(item => new ProdutoCatalogo({ id: v4(), ...item }))
  },
  [REMOVE_CARD_ROW] (state, { item }) {
    const index = state.form.findIndex(f => f.id === item.id)
    if (~index) {
      state.form.splice(index, 1)
    }
  },
  [CHECK_ALL] (state) {
    state.form.forEach(item => { item.checked = true })
  },
  [UNCHECK_ALL] (state) {
    state.form.forEach(item => { item.checked = false })
  },
  [APPLY_TO_CHECKED] ({ form, opcoes }) {
    const changes = Object.fromEntries(Object.entries(opcoes).filter(p => p[1] !== null))
    form.forEach((item, index) => {
      if (item.checked) {
        Object.assign(form[index], changes)
      }
    })
  },
}

const actions = {
  [NEXT] ({ dispatch, commit, state, getters }) {
    if (getters.produtoCatalogo.length) {
      commit(SET_FORMS, {
        items: getters.produtoCatalogo.map(p => new ProdutoCatalogo({ ...p })),
      })
    } else {
      commit(SET_FORMS, { items: [] })
      dispatch(ADD_ITEM_CATALOGO)
    }
  },
  async [BOOTSTRAP] ({ dispatch, commit, state }) {
    commit(RESET_STATE)
    await dispatch(LOAD_PRODUTOS)
  },
  async [LOAD_PRODUTOS] ({ dispatch, commit, state }) {
    const produtosData = await api.listEntidade('produto').then(p => p, () => [])
    commit(SET_PRODUTOS, { produtos: produtosData })
  },
  [CANCEL] ({ dispatch, commit, state }) {
    commit(SET_DIALOG, { dialog: false })
  },
  async [REMOVE_ITEM_CATALOGO] ({ state, dispatch, commit }, payload) {
    const { saved } = payload.item

    if (!saved) {
      commit(REMOVE_CARD_ROW, payload)
    } else {
      const status = await dispatch('catalogos/REMOVE_ITEM_FROM_CATALOGO', payload.item, { root: true })
      if (status) {
        commit(REMOVE_CARD_ROW, payload)
      }
    }
    if (!state.form.length) {
      dispatch(ADD_ITEM_CATALOGO)
    }
  },
  [OPEN_FORM] ({ dispatch, commit, state }, { item }) {
    if (item) {
      commit(SET_EDIT_FORM, { data: item })
      commit(EDIT_FORM, { edit: true, id: item.id })
      commit(SET_PRODUTO, item.produto_id)
      dispatch(NEXT)
    } else {
      commit(SET_PRODUTO, null)
      commit(EDIT_FORM, { edit: false })
    }
    commit(SET_DIALOG, { dialog: true })
  },
  [ADD_ITEM_CATALOGO] ({ state, commit, getters }) {
    const { id: produto_id } = getters.produto
    state.form.splice(0, 0, new ProdutoCatalogo({
      produto_id: produto_id,
      id: v4(),
      disponibilidade: 1,
      preco: null,
      estoque: 0,
      saved: false,
    }))
  },
  async [SUBMIT] ({ dispatch, commit, state: { form: formData, edit, editForm, produto_id }, rootState, getters, rootGetters }) {
    const form = { items: formData, produto_id }
    const conflicts = getters.produtoCatalogo.filter(
      item => ~formData.findIndex(({ unidade_negocio_id }) => item.unidade_negocio_id === unidade_negocio_id),
    )
    const unidades_conflict = conflicts.map(item => item.unidade_negocio_nome_fantasia)

    if (!edit && conflicts.length > 0) {
      const { isConfirmed } = edit ? { isConfirmed: true } : await Swal.fire({
        icon: 'question',
        title: 'Atenção',
        html: `O Produto ja está registrado nas seguintes unidades: <strong>${unidades_conflict.join('</strong>, <strong><br>')}</strong>.<br> Deseja adicionar o Produto mesmo assim?`,
        showCancelButton: true,
        confirmButtonColor: '#109010',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Salvar',
        cancelButtonText: 'Cancelar',
        reverseButtons: true,
        focusConfirm: false,
      })
      if (!isConfirmed) {
        return
      }
    }

    Swal.fire({
      title: 'Enviando dados!',
      timerProgressBar: true,
      showConfirmButton: false,
      allowOutsideClick: false,
      willOpen: () => {
        Swal.showLoading()
      },
      didOpen: () => {
        api.cadastrarEntidade(form, 'catalogo').then(
          data => {
            data.forEach(({ id, unidade_negocio_id }) => {
              commit(
                'catalogos/' +
                (~conflicts.findIndex(item => item.unidade_negocio_id === unidade_negocio_id)
                  ? SET_PRODUTO_CATALOGO
                  : ADD_PRODUTO_CATALOGO),
                {
                  item: {
                    ...new ProdutoCatalogo({ ...form, id }),
                    ...rest,
                  },
                },
                { root: true },
              )
            })
            Swal.hideLoading()
            const { rotulo, id, ...rest } = getters.produto
            Swal.fire({
              icon: 'success',
              title: 'Catálogo atualizado com sucesso!',
              showConfirmButton: false,
              timer: 5500,
            })
          },
          error => {
            window.Toast.fire(error.errors ? error.errors : error.message, '', 'error')
          },
        )
          .then(() => {
            dispatch('catalogos/' + LOAD_BATCH, null, { root: true })
            commit(SET_DIALOG, { dialog: false })
          })
      },
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
