<template>
  <v-form
    ref="form"
    dense
    lazy-validation
  >
    <v-row>
      <v-col
        cols="12"
        sm="6"
      >
        <v-btn
          class="text-none text-white"
          small
          block
          color="primary"
          elevation="2"
          :disabled="form.length === unidadesNegocio.length"
          rounded
          @click="ADD_ITEM_CATALOGO"
        >
          <v-icon>
            mdi-plus
          </v-icon>
          Adicionar nova Unidade de Negócio ao catálogo.
        </v-btn>
      </v-col>
      <v-col
        cols="12"
        sm="6"
      >
        <catalogo-dialog-menu />
      </v-col>
    </v-row>
    <v-card
      v-for="formItem in form"
      :key="`${formItem.id}-${formItem.unidade_negocio_id}`"
      class="form-card"
      elevation="3"
    >
      <v-container class="pa-5">
        <v-row class="mr-3">
          <v-col
            cols="12"
            sm="6"
          >
            <v-autocomplete
              v-model="formItem.unidade_negocio_id"
              :items="filteredUnidades"
              item-text="nome_fantasia"
              item-value="id"
              item-disabled="disabled"
              :disabled="formItem.saved"
              label="Unidade de negócio*"
              :rules="[rules.required]"
              autocomplete="off"
              validate-on-blur
              dense
              outlined
              rounded
            >
              <template
                #item="{item}"
              >
                <v-container
                  class="rounded-lg"
                  fluid
                >
                  <v-row
                    dense
                    justify="space-between"
                  >
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ item.nome_fantasia }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ ( item.cnpj + '  -  ' + item.razao_social ) }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-row>
                </v-container>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col
            cols="12"
            sm="3"
          >
            <base-vuetify-money
              v-model="formItem.preco"
              :options="money"
              :rules="getPrecoRules(formItem.disponibilidade)"
              maxlength="10"
              label="Preço (R$) *"
              validate-on-blur
              dense
              outlined
              rounded
            />
          </v-col>
          <v-col
            cols="12"
            sm="3"
          >
            <v-text-field
              v-model="formItem.pedido_min"
              :rules="[rules.required, rules.numeric, rules.gt(0)]"
              maxlength="10"
              label="Pedido Mínimo (Quantidade) *"
              validate-on-blur
              dense
              outlined
              rounded
            />
          </v-col>
        </v-row>
        <div style="position: absolute; top: 8px; right: 8px;">
          <v-checkbox
            v-model="formItem.checked"
            class="mt-0"
            hide-details
          />
        </div>

        <v-row
          dense
          justify="end"
        >
          <v-col
            cols="11"
            md="11"
          >
            <v-radio-group
              v-model="formItem.disponibilidade"
              label="Disponibilidade: "
              dense
              class="mt-0"
              hide-details
              mandatory
              row
              :disabled="formItem.disponibilidade === DisponibilidadeCatalogo.EM_OFERTA"
            >
              <v-radio
                label="Imediata"
                :value="1"
              />
              <v-radio
                label="Sob demanda"
                :value="0"
              />
              <v-radio
                label="Sob cotação"
                :value="2"
              />
              <v-radio
                label="Em Promoção"
                :value="3"
                disabled
              />
            </v-radio-group>
          </v-col>
          <v-spacer />
          <v-col
            cols="auto"
            align-self="end"
          >
            <v-btn
              icon
              max-height="15"
              title="Excluir Produto da Unidade de Negócio do catálogo."
              :disabled="formItem.disponibilidade === DisponibilidadeCatalogo.EM_OFERTA"
              @click="REMOVE_ITEM_CATALOGO({ item: formItem } )"
            >
              <v-icon color="red darken-3">
                mdi-trash-can-outline
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-form>
</template>
<script>
  import { ADD_ITEM_CATALOGO, REMOVE_ITEM_CATALOGO, SUBMIT } from '@/store/modules/forms/catalogo'
  import rules from '../../utils/formRules'
  import { DisponibilidadeCatalogo } from '../../utils/constants'
  import { mapState, mapActions, mapGetters } from 'vuex'
  import { mapMultiRowFields } from 'vuex-map-fields'
  import CatalogoDialogMenu from './CatalogoDialogMenu'

  export default {
    name: 'CardsCatalogo',
    components: { CatalogoDialogMenu },
    data () {
      return {
        DisponibilidadeCatalogo,
        money: {
          locale: 'pt-BR',
          precision: 2,
        },
        rules,
        unidade_negocio: null,
        preco: null,
        pedido_min: 1,
      }
    },
    computed: {
      ...mapState('catalogos', { unidadesNegocio: state => state.unidades_negocio }),
      ...mapMultiRowFields('form/catalogo', ['form']),
      filteredUnidades () {
        const formUnidades = this.form.map(f => f.unidade_negocio_id)
        return this.unidadesNegocio.map(un => ({ ...un, disabled: formUnidades.includes(un.id) }),
        )
      },
      ...mapGetters('form/catalogo', ['color']),
    },
    methods: {
      ...mapActions('form/catalogo', [SUBMIT, ADD_ITEM_CATALOGO, REMOVE_ITEM_CATALOGO]),
      validate () {
        return this.$refs.form.validate()
      },
      resetValidation () {
        this.$refs.form.resetValidation()
      },
      getPrecoRules (disponibilidade) {
        if (disponibilidade !== 2) {
          return [this.rules.required, this.rules.moneyGt(0)]
        }
        return [this.rules.required]
      },
    },
  }
</script>
<style lang="scss" scoped>

.conflict {
  border: 5px solid red !important
}

// .card-icon {
//   position: absolute;
//   right: 2px;
//   bottom: 2px;
// }

/* html {
  overflow: hidden !important;
} */

.card-scroll-container {
  overflow: auto
}

/* width */
::-webkit-scrollbar {
  width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px lightgray;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: lighten(grey, 10%);
  border-radius: 2px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #143693;
}

.v-card__text {
  flex-grow: 1;
  overflow: auto;
}

.form-card {
  margin-top: 30px;
  border-radius: 16px;
}
</style>
