import { v4 } from 'uuid'
export class ProdutoCatalogo {
  constructor ({
    id,
    produto_id,
    unidade_negocio_id,
    unidade_negocio_nome_fantasia,
    fornecedor,
    preco,
    estoque,
    pedido_min,
    disponibilidade,
    checked,
    saved = true,
  } = { id: v4() }) {
    this.id = id
    this.produto_id = produto_id
    this.unidade_negocio_id = unidade_negocio_id
    this.unidade_negocio_nome_fantasia = unidade_negocio_nome_fantasia
    this.fornecedor = fornecedor
    this.preco = preco
    this.estoque = estoque
    this.pedido_min = pedido_min
    this.disponibilidade = disponibilidade
    this.checked = checked || false
    this.saved = saved
  }
}

export function createProdutoCatalogo (data) {
  return Object.freeze(new ProdutoCatalogo(data))
}
